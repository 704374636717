main {
    min-height: 80vh;
}

@font-face {
    font-family: "screwed-aoe-regular";
    src: local("screwed-aoe-regular"),
     url("./fonts/Screwed_AOE.ttf") format("truetype");
    font-weight: regular;
    }

.rating span{
    margin: 0.1rem;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: flex;
}

.carousel-caption {
    position: absolute;
    top: 0;
}

.carousel-caption h4 {
    color: #fff;
}

.carousel img {
    display: block;
    height: 300px;
    padding: 30px;
    margin: 40px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.carousel a {
    margin: 0 auto;
}
@media (max-width: 900px) {
    .carousel-caption h2 {
        font-size: 2.5vw;
    }
}

h3{
    padding: 1rem 0 0 0;
}

.productTitle {
    text-decoration: none;
}

.productTitle:hover {
    text-decoration: underline;
}

h1 {
    font-size: 1.8rem;
    padding: 1rem 0;
}

h2 {
    font-size: 1.4rem;
    padding: 0.5rem 0;
}

.tox-statusbar__branding {
    display: none;
}

#nav-brand-container:hover #charlie {
    animation: charlie 3s ease infinite;
    transform-origin: center;
    transform-box: fill-box;
}


@keyframes charlie {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

#none {
    display: none;
}

.navbar-title {
    font-size: 1.2rem;
    font-family: 'screwed-aoe-regular', sans-serif;
}

.raise:hover,
.raise:focus {
  padding: 1px;
  transition: .5s;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(255, 255, 255, 0.06));
  filter: drop-shadow(5px 5px 5px rgba(255, 255, 255, 0.06));
}

.nav-icon {
    color: #C7CDD2;
}

.nav-icon:hover {
    color: white;
}

a {
    color: white;
    text-decoration: none;
}

#otherDropDown::after {
    color: white;
}

.face-svg:hover {
    padding: 13px;
    cursor: pointer;
}

#mood-svg:hover {
    width: 98%;
    cursor: pointer;
}